import React, {Component} from 'react'
import injectSheet from 'react-jss'
import AliceCarousel from 'react-alice-carousel'
import "react-alice-carousel/lib/alice-carousel.css"

import MooneeValley from '../images/logos/mooneyvalley.jpg'
import YarraRanges from '../images/logos/yarraranges.png'
import Manningham from '../images/logos/manningham.png'
import Hume from '../images/logos/hume.jpg'
import HumanServices from '../images/logos/humanservices.jpg'
import FremantleMedia from '../images/logos/fremantlemedia.jpg'

const styles = {
  carouselSlide: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	carouselImage: {
		maxHeight: '64px'
	}
}

class LogoCarousel extends Component {
  render() {
    const {classes} = this.props
    return(
      <AliceCarousel
        dotsDisabled
        autoPlay
        autoPlayInterval={5000}
        responsive={
{
	0: {
		items: 1
	},
	567: {
		items: 2
	},
	767: {
		items: 3
	},
	1024: {
		items: 4
	}
}}
      >
          <div className={classes.carouselSlide}>
            <img alt="Fremantle Media Australia" src={FremantleMedia} className={classes.carouselImage}/>
          </div>
          <div className={classes.carouselSlide}>
            <img alt="City of Moonee Valley" src={MooneeValley} className={classes.carouselImage}/>
          </div>
          <div className={classes.carouselSlide}>
            <img alt="Yarra Ranges Council" src={YarraRanges} className={classes.carouselImage}/>
          </div>
          <div className={classes.carouselSlide}>
            <img alt="Hume City Council" src={Hume} className={classes.carouselImage}/>
          </div>
          <div className={classes.carouselSlide}>
            <img alt="Manningham" src={Manningham} className={classes.carouselImage}/>
          </div>
          <div className={classes.carouselSlide}>
            <img alt="Department of Human Services" src={HumanServices}className={classes.carouselImage}/>
          </div>
      </AliceCarousel>
    )
  }
}

export default injectSheet(styles)(LogoCarousel)
